@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchant-details {
  height: 100%;

  @include mq('tablet') {
    padding: 14px 27px 0;
  }

  @include mq('desktop-s') {
    padding: 22px 42px 0;
  }

  .marmita-tab {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__tablist {
      @include mq('desktop-s') {
        display: flex;
      }

      &-container {
        padding: 0;
      }

      .marmita-tab__label {
        cursor: pointer;

        &:hover {
          border-bottom: $primary-color 2px solid;
        }

        &--active {
          color: $primary-color;
        }
      }
    }

    &__panel-container {
      height: 100%;
      overflow-y: auto;
    }
  }
}
