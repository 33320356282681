@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-scheduling-button {
  // Safari does not support grid in buttons, isolating grid layout into __grid
  // https://github.com/rachelandrew/gridbugs#10-some-html-elements-cant-be-grid-containers
  // https://codepen.io/rachelandrew/pen/dzXwRJ
  background: #fff;
  border: 1px solid $light-grey-color-100;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  border-radius: $border-radius-default;
  cursor: pointer;
  flex: 1;
  height: 58px;
  padding: 0 10px;

  @include mq('mobile') {
    min-width: 188px;
    padding: 0 16px;
  }

  @include mq('tablet') {
    height: 62px;
  }

  &:disabled {
    cursor: default;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 14px;
    align-items: center;

    @include mq('mobile') {
      grid-template-columns: 1fr 20px;
    }
  }

  &__wrapper {
    display: grid;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    margin-top: 2px;
    line-height: rem(16);
  }

  &__day {
    text-align: left;
    color: $text-color-super-darken;
    font-size: rem(14);
    line-height: rem(18);
    font-weight: 400;

    &--express {
      color: $primary-color;
    }

    &--icon {
      fill: $primary-color;
      stroke: $primary-color;
      margin-right: 10px;
    }
  }

  &__time {
    text-align: left;
    color: $text-color;
    font-size: rem(12);
    padding: 0 2px 0 0;
    font-weight: 300;
  }

  &__price {
    color: $text-color;
    font-size: rem(12);

    &--free {
      color: $green-color;
    }
  }

  &__icon {
    @include colorize-icon($primary-color);

    height: 24px;
    width: 24px;
  }
}
