@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.scheduling-methods-list-section {
  margin-bottom: 28px;

  &:last-child {
    margin-bottom: 95px;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  &__title {
    color: #3e3e3e;
    font-size: rem(18);
    font-weight: 500;
    line-height: 22px;
    margin: 0 0 12px;

    @media (min-width: 768px) {
      margin-bottom: 25px;
    }
  }

  &__item {
    margin-bottom: 0;

    @media (min-width: 768px) {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
