@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-card {
  display: grid;
  height: 90px;
  padding: 12px;
  border: 1px solid $light-grey-color-100;
  border-radius: $border-radius-default;
  width: 100%;
  grid-template: 1fr 20px / 60px 1px 1fr;
  grid-template-areas:
    'figure divider title'
    'figure divider content';
  grid-column-gap: 12px;

  &:hover {
    border: 1px solid $medium-grey-color-lighten;
  }

  &--with-voucher {
    border-radius: $border-radius-default $border-radius-default 0 0;
  }

  &--simple-card {
    grid-template-rows: 1fr;
    padding: 0 12px;
  }

  &__link {
    text-decoration: none;
  }

  @include mq('tablet', 'max') {
    min-width: calc(100vw - #{2 * $padding-default});
  }

  @include mq('desktop-s') {
    height: 130px;
    grid-template-columns: 85px 1px 1fr;
    grid-column-gap: 20px;
    padding: $padding-default;
    cursor: pointer;
  }

  &::before {
    grid-area: divider;
    content: '';
    width: 1px;
    background-color: $light-grey-color-100;
  }

  &__title {
    grid-area: title;
    margin: 0;
    padding: 0;

    &--simple-card {
      align-self: center;
    }
  }

  %texts-default {
    font-weight: 300;
    color: $text-color;
    font-size: rem(14);
    line-height: rem(15);

    @include mq('desktop-s') {
      font-size: rem(14);
      line-height: rem(17);
    }

    @include mq('mobile', max) {
      font-size: rem(11);
    }
  }

  &__status {
    position: absolute;
    margin: 0;
    background: rgb(0 0 0 / 40%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(12);
    line-height: rem(20);
    color: #fff;
    border-radius: 50%;
    width: 54px;
    height: 54px;

    @include mq('desktop-s') {
      font-size: rem(16);
      width: 85px;
      height: 85px;
    }
  }

  &__figure {
    grid-area: figure;
    background: #fff;
    overflow: hidden;
    position: relative;
    height: 100%;
    border-radius: 4px 0 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    .restaurant-card__img-logo {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      border: 1px solid $light-grey-color-100;
      transition: 200ms;
      object-fit: contain;

      @include mq('desktop-s') {
        width: 85px;
        height: 85px;
      }
    }

    .restaurant-card__img-cover {
      height: 100%;
      width: 100%;
    }
  }

  &__header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    position: relative;
    padding: 0 0 2px;

    @include mq('desktop-s') {
      padding: 0 0 10px;
    }

    .restaurant-name {
      color: $text-color-super-darken;
      font-size: rem(14);
      line-height: rem(14);
      font-weight: 500;

      @include truncate;

      display: block;
      height: 18px;

      @include mq('desktop-s') {
        font-size: rem(16);
        line-height: rem(16);
      }
    }
  }

  &__info {
    width: 100%;

    @extend %texts-default;

    display: flex;
    align-items: center;
  }

  &__footer {
    align-items: center;
    margin: 0;
    width: 100%;

    @extend %texts-default;

    display: flex;
  }

  &__super {
    height: 18px;

    .icon-marmita {
      position: relative;
      top: -3px;
      padding: 0;

      @include colorize-icon($super-color);
    }
  }

  &__separator {
    margin-left: 5px;
    margin-right: 5px;
  }

  .restaurant-card__mini-tag {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      margin: 0 5px 0 2px;
      display: flex;
      max-width: 12px;

      @media (min-width: 768px) {
        svg {
          width: 14px;
        }
      }
    }

    &.green {
      color: $green-color;
    }

    &.focused {
      background-color: $almost-white-color;
      padding: 2px 3px;
      border-radius: 4px;
      margin: 0;
    }

    &.nowrap {
      white-space: nowrap;
    }
  }
}
