@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.rating-counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border-bottom: 1px solid $light-grey-color;
  padding-bottom: 15px;

  @include mq('tablet') {
    padding-bottom: 20px;
  }

  @include mq('desktop-s') {
    padding-bottom: 35px;
  }

  &__average {
    color: $text-color-super-darken;
    font-size: rem(36);
    font-weight: 500;
    line-height: rem(26);
    margin: 0 0 10px;
  }

  &__total {
    font-weight: 300;
    font-size: rem(12);
    line-height: rem(16);
    color: $text-color-lighten;
  }
}
