@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.rating-evaluation-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mq('desktop-s') {
    font-size: rem(15);
  }

  &__username {
    font-size: rem(18);
    color: $black-color;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 8px;
    display: block;
    text-transform: capitalize;
    width: 100%;
    text-align: left;
  }

  &__rate-wrapper {
    display: flex;
  }

  &__rate {
    font-weight: 500;
    font-size: rem(15) px;
    color: $black-color;
    margin-right: 5px;
  }

  &__date {
    display: inline;
    font-weight: 300;
    color: $gray-color-lighten;

    @include mq('desktop-s') {
      line-height: em(22);
      font-size: rem(16);
    }
  }
}
