@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.rating-restaurant-answer {
  background-color: $light-grey-color;
  padding: $padding-default;
  border-radius: $border-radius-default;
  margin-top: 15px;
  font-size: rem(15);
  line-height: 1.46;

  @include mq('desktop-s') {
    background-color: transparent;
    padding: 5px 30px 0;
  }

  &__name {
    margin: 0 0 10px;
    font-weight: 500;
    color: $black-color;
    font-size: rem(14);
  }

  &__answer {
    font-weight: 300;
    margin: 0;
    font-size: rem(14);
  }
}
