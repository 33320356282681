@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.scheduling-modal {
  font-family: $font-family;
  box-sizing: border-box;
  padding: 0 16px;
  position: relative;

  @media (min-width: 768px) {
    padding: 13px 33px;
    max-height: 80vh;
    min-width: 550px;
  }

  &__header {
    box-sizing: border-box;
    height: 45px;
    margin-bottom: 24px;
    padding: 10px 0;
    position: relative;

    .toast {
      position: absolute;
      top: 5px;
      max-width: 100%;
      width: 95%;
    }

    @media (min-width: 768px) {
      height: auto;
      margin-bottom: 29px;
      padding: 0;

      .toast {
        top: -10px;
      }
    }
  }

  &__back-btn {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__title {
    font-weight: 500;
    font-size: rem(12);
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    line-height: 27px;
    color: #3e3e3e;

    @media (min-width: 768px) {
      font-size: rem(18);
      line-height: 22px;
      text-transform: initial;
      font-weight: normal;
    }
  }

  &__days {
    margin-bottom: 52px;

    @media (min-width: 768px) {
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 32px;
      margin-bottom: 27px;
    }
  }

  &__actions {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    left: 0;
    box-shadow: inset 0 1px 0 #f2f2f2;
    padding: 20px;
    background: #fff;
    box-sizing: border-box;

    @media (min-width: 768px) {
      position: relative;
      padding: 20px 0;
    }
  }

  &__submit-btn {
    width: 100%;
    font-size: rem(14);
  }
}

@include mq('tablet') {
  .scheduling-modal__wrapper {
    padding-top: 0;
    padding-bottom: 0;

    .marmita-modal__inner-content-scroll {
      overflow-x: hidden;
    }

    .marmita-modal__inner-content {
      // The values set in modal.scss were causing scrolling issues
      // showing multiple scrollbars, and hiding content after some scroll point
      // so reset of these values to fix it
      width: initial;
      height: initial;
      overflow: hidden;
    }
  }
}
