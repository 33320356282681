@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-menu-search {
  position: relative;
  width: 100%;

  input::placeholder {
    text-align: left;
    color: $text-color;
    opacity: 0.7;
    font-size: rem(16);
  }

  .icon-marmita {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 26px;
    height: 26px;

    @include colorize-icon(#b1b1b1);

    pointer-events: none;
  }

  &__field {
    display: flex;
    height: 40px;
    width: 99%;
    padding-left: 55px;
    border: none;
    border-radius: $border-radius-default;
    font-size: rem(16);
  }

  .btn-icon {
    position: absolute;
    top: 0;
    right: 20px;

    & > canvas {
      color: $input-color;
    }

    .icon-marmita--close {
      left: 0;
      height: 30px;
      width: 30px;
    }
  }
}
