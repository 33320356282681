@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.delivery-method-tooltip {
  width: 100%;
  height: 300px;
  box-shadow: 0 -2px 16px rgb(0 0 0 / 8%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-family: $font-family;

  @media (min-width: 768px) {
    width: 475px;
    height: auto;
  }

  &__top-border {
    width: 40px;
    height: 4px;
    background: $light-grey-color-300;
    border-radius: 2.5px;
  }

  &__image-container {
    margin: 20px 0 10px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    height: 100%;
    width: auto;
  }

  &__text-container {
    width: 90%;
    text-align: center;

    @media (min-width: 768px) {
      width: 68%;
    }
  }

  &__title {
    font-weight: 500;
    font-size: rem(18);
    line-height: 22px;
    margin: 0 0 8px;
    color: $text-color-super-darken;

    @media (min-width: 768px) {
      font-size: rem(16);
    }
  }

  &__description {
    font-weight: 300;
    text-align: center;
    font-size: rem(14);
    line-height: 20px;
    color: $text-color;
    margin: 0;
  }

  &__button {
    font-weight: 500;
    font-size: rem(14);
    line-height: 18px;
    color: $primary-color;
    background: none;
    border: none;
    margin: 35px 0;
    font-family: $font-family;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}
