@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurants-list {
  &__header,
  &__empty,
  &__error {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $padding-default $padding-default 12px;
  }

  &__empty {
    justify-content: center;
  }

  &__error-wrapper {
    @include mq('tablet') {
      @include page-container;
    }
  }

  &__header {
    padding: 0 $padding-default;

    @include mq('desktop-s') {
      @include page-container;

      padding-top: $padding-default;
    }

    .title {
      padding: $padding-default 0 0;

      @include section-title;

      margin: 0;

      @include mq('desktop-s') {
        padding: 0 0 $space-orange;
      }
    }

    &-space {
      padding-top: 0;

      .title {
        padding: $padding-default 0;

        @include mq('desktop-s') {
          padding: 0 0 $space-orange;
        }
      }
    }
  }

  &__error {
    padding: 5px 20px;
    color: $medium-grey-color;

    @include mq('tablet') {
      padding: 5px 0;
    }
  }

  &__error-header {
    padding: 5px 20px;

    @include mq('tablet') {
      padding: 5px 0;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: $space-thin-blue;

    @include mq('mobile-l') {
      grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    }

    @include mq('desktop-s') {
      gap: $space-orange;
    }
  }

  &__item-wrapper {
    transition: 200ms;
    height: min-content;
    border-radius: $border-radius-default;
    text-decoration: none;
  }

  .marmita-spinner {
    margin: $padding-default 0;
  }
}
