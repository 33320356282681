@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.voucher-widget {
  &__available-voucher {
    padding: 8px 12px;
    margin-bottom: 16px;
    color: $promotion-tag-color;
    background: $promotion-tag-color-light;
    width: fit-content;
    font-size: rem(12);
    border-radius: $border-radius-default;
    font-weight: 300;

    .icon-marmita {
      height: 12px;
      width: 12px;
      margin-right: 6px;

      @include colorize-icon($promotion-tag-color);
    }

    @include mq('desktop-s') {
      font-size: rem(14);
    }
  }
}
