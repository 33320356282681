@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.delivery-methods-modal {
  padding: 20px 10px 8px;

  @media (min-width: 768px) {
    width: 400px;
    height: 300px;
  }

  &__title {
    font-size: rem(18);
    line-height: rem(22);
    color: $text-color-super-darken;
    margin: 0 0 15px;
    padding: 0;
    text-align: center;
    font-weight: 500;
  }

  &__confirm-btn {
    margin: 32px auto 0;
    width: 90%;
    display: block;
  }

  &__option {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 18px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #fff;

    &--checked {
      border: solid 1px #ea1d2c;
    }

    &--disabled {
      opacity: 0.5;
    }
  }

  &__option-label {
    display: flex;
    flex-direction: column;
    margin-left: 13px;
  }

  &__label-title {
    font-size: rem(14);
    line-height: 20px;
    color: $text-color-super-darken;
  }

  &__label-description {
    font-size: rem(14);
    line-height: 18px;
    color: $text-color;
  }

  &__radio {
    appearance: none;
    margin: 0 0 0 auto;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: $light-grey-color-100;
    border-color: $light-grey-color-100;
    cursor: pointer;

    &:checked {
      border: solid 6px $primary-color;
      background-color: #fff;
    }
  }
}
