@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.online-payment {
  &__debit-disclaimer {
    color: $gray-color-lighten;
    font-size: rem(15);
    margin: 0 auto 20px;
    text-align: center;
    width: 200px;
  }

  &__cards-list {
    display: flex;
    justify-content: space-around;
    margin: 0 auto 20px;
    max-width: 180px;
  }

  &__card-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 25px;
    width: 29px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__question-button {
    @include question-mark-button;

    margin-left: 5px;
  }
}
