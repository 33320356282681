@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchant-details-schedule {
  padding-top: 32px;

  &__day {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: rem(14);
    margin-bottom: 28px;

    &-title {
      flex: 3;
      display: flex;
      flex-direction: row;
      align-items: center;

      &-text {
        font-weight: 300;
        color: #3e3e3e;

        &--today {
          color: $gray-color-700;
          font-weight: 500;
          margin-left: 9px;
        }
      }
    }

    &-schedule {
      color: #717171;
      flex: 5;

      &--today {
        color: $gray-color-700;
        font-weight: 500;
      }

      &--closed {
        color: #3e3e3e;
      }
    }
  }
}
