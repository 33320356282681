@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.rating-container {
  width: 100%;
  height: 100%;
  max-width: 420px;
  margin: 0 auto;
  overflow: auto;

  @include mq('desktop-s') {
    width: 95%;
    padding-right: 5px;
  }

  &__text {
    text-align: center;
  }

  &__title {
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(17);
    margin: 0 0 5px;
    text-transform: uppercase;
    color: $black-color;
  }

  &__merchant {
    font-weight: 300;
    font-size: rem(12);
    line-height: rem(15);
    text-align: center;
    margin: 0;
    color: $text-color-lighten;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;

    .marmita-spinner {
      position: static;
      transform: none;
    }
  }
}
