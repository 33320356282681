@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.order-receipt {
  &__wrapper {
    align-items: center;
    justify-content: space-between;
    display: flex;
    position: relative;

    .form-input {
      flex-grow: 1;
      margin: 5px 0;
    }

    .form-input__field[disabled] {
      opacity: 0.4;
    }

    .marmita-toggle {
      margin-left: 10px;
      top: -8px;

      @include mq('desktop') {
        position: absolute;
        right: 20px;
        top: 20px;
      }

      @include mq('mobile', max) {
        &__slider {
          &--checked {
            &::before {
              left: -2px;
            }
          }
        }
      }
    }
  }

  &__dialog {
    margin: 0;
    font-size: rem(14);
    color: $primary-color;
    font-style: italic;
  }

  &__submit-button {
    margin-top: 30px;
  }
}
