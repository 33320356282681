@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.checkout-card-form {
  &__flex {
    display: flex;

    .form-input {
      flex: 1;
    }

    .form-input:first-child {
      margin-right: 10px;
    }

    @include mq('tablet', max) {
      height: 115px;
    }
  }

  &__card-container {
    align-items: center;
    display: flex;

    .form-input {
      flex: 1;
      margin-right: 10px;
    }
  }

  &__question-button {
    @include question-mark-button;

    align-self: center;
    bottom: 8px;
    margin-left: 10px;
    position: relative;
  }

  &__brand-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 25px;
    width: 29px;
    animation: fade-in 300ms;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__input-icon {
    height: 20px;
    width: 15px;
  }

  @include mq-between('desktop-s', 'desktop') {
    max-width: 90%;
  }

  @include mq('desktop-s') {
    .order-receipt__wrapper {
      position: relative;

      .marmita-toggle {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }

  .btn[type='submit'] {
    @include mq('desktop-s', 'max') {
      width: 100%;
    }
  }
}
