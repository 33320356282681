@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchant-info {
  border-radius: $border-radius-default $border-radius-default 0 0;
  padding: 20px $padding-default 0 $padding-default;
  margin-top: 118px;
  position: relative;
  top: -5px;
  background-color: white;
  display: flex;
  flex-direction: column;

  @include mq('tablet') {
    margin-top: 200px;
  }

  @include mq('desktop-s') {
    padding: 0 30px;
    margin-bottom: 20px;
    margin-top: 30px;
    top: 0;
  }

  @include mq('desktop') {
    max-width: 1366px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &__content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__logo {
    border-radius: 100%;
    height: 42px;
    width: 42px;
    margin-right: 12px;
    border: 1px solid rgb(0 0 0 / 10%);
    align-self: flex-start;

    @include mq('tablet') {
      height: 70px;
      width: 70px;
      margin-right: 20px;
    }

    @include mq('desktop-s') {
      height: 75px;
      width: 75px;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @include mq('desktop-s') {
      flex-direction: row;
      align-items: center;
    }

    .restaurant-rating__rating-wrapper {
      position: unset;
      top: unset;
      right: unset;

      @include mq('desktop-s') {
        display: inline;
        margin-left: 12px;
        line-height: 44px;
      }

      a {
        flex-direction: row-reverse;

        @include mq('desktop-s') {
          display: inline;
          line-height: 44px;
        }
      }

      .restaurant-rating__button {
        font-weight: 500;
        font-size: rem(12);
        padding: 0;

        @include mq('tablet') {
          font-size: rem(16);
        }

        @include mq('desktop-s') {
          display: inline;
          line-height: 44px;
        }

        .icon-marmita {
          @include mq('tablet') {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }

  &__title-container {
    flex: 1;
    display: inline;
  }

  &__title {
    display: inline;
    font-size: rem(18);
    font-weight: 500;
    line-height: 24px;
    color: $text-color-super-darken;
    margin: 0;
    margin-bottom: 2px;

    @include mq('tablet') {
      font-size: rem(26);
      font-weight: 400;
      line-height: 32px;
      letter-spacing: -1px;
    }

    @include mq('desktop-s') {
      font-size: rem(36);
      line-height: 44px;
      margin-bottom: 0;
    }
  }

  &__super-restaurant {
    @include colorize-icon($super-color);

    display: inline;
    align-items: center;
    margin-left: 8px;
    line-height: 24px;
    padding: 0;
    vertical-align: baseline;

    svg {
      height: 14px;
      width: 14px;
    }

    @include mq('tablet') {
      line-height: 32px;

      svg {
        height: 18px;
        width: 18px;
      }
    }

    @include mq('desktop-s') {
      margin-left: 12px;
      line-height: 44px;

      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  &__detail-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-top: 2px;
    height: fit-content;
    align-items: center;

    @include mq('tablet') {
      margin-top: 8px;
      padding-left: 3px;
    }

    @include mq('desktop-s') {
      flex: unset;
    }
  }

  &__minimum-order {
    color: $search-placeholder-home;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-left: 4px;

    @include mq('tablet') {
      border-left: $search-placeholder-home 1px solid;
      padding-left: 30px;
      margin-left: 30px;
    }

    &-icon {
      display: inline-block;
      border-radius: 100%;
      width: 16px;
      height: 16px;
      font-weight: 700;
      font-size: 11px;
      text-align: center;
      color: white;
      background-color: $search-placeholder-home;
      margin-right: 8px;
    }
  }

  &__voucher-widget {
    margin-top: 10px;

    @include mq('desktop-s') {
      margin-left: 95px;
    }

    .delivery-discount-with-min-order--menu {
      margin: 0;
    }
  }

  &__shopping-list-button {
    margin-left: 30px;
    padding-left: 20px;
    position: relative;

    &::before {
      content: '';
      width: 1px;
      height: 16px;
      background-color: #a6a6a5;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
