@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.voucher-header {
  position: fixed;
  width: 100vw;
  top: 80px;
  background-color: $light-grey-color-100;
  z-index: $voucher-header-z-index;
  transition: transform 0.3s ease;

  &--hidden {
    transform: translateY(-70px);
  }

  &-band {
    background-color: $green-color;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-marmita--voucher-header {
      width: 40px;
    }
  }

  &-container {
    @include page-container;

    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    grid-gap: 30px;
    padding: $space-blue 40px;

    @include mq('desktop-s') {
      padding: $space-blue 30px $space-blue 50px;
      grid-template-columns: repeat(3, minmax(266px, 380px));
    }
  }

  &-bg {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: $voucher-bg-z-index;
    background-color: $text-color-super-darken;
    opacity: 0.8;

    +div {
      margin-top: 80px;
    }

    &--hidden {
      display: none;
    }
  }
}

.voucher-header-mobile {
  display: flex;
  padding: 0;

  .voucher-slim-card {
    height: 62px;
    margin: 0 $space-blue;

    &__title {
      font-size: em(14);
    }
  }
}

.voucher-list-container {
  max-height: calc(100vh - 140px);
  overflow: scroll;
  overflow-x: hidden;
  transition: max-height 200ms linear;
  visibility: visible;

  &--hidden {
    max-height: 0;
    visibility: 0;
    overflow: hidden;
  }
}
