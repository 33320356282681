@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchant-banner {
  position: relative;
  margin: auto;

  @include mq('desktop-s') {
    padding: 25px 30px 0;
  }

  @include mq('desktop') {
    padding: 35px 30px 0;
    padding-right: 30px;
    max-width: 1366px;
  }

  &__wrapper {
    display: flex;
    position: fixed;
    flex-direction: row;
    align-items: center;
    width: 100%;
    color: $light-grey-color-200;
    height: 118px;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    justify-content: center;

    @include mq('tablet') {
      height: 200px;
    }

    @include mq('desktop-s') {
      border-radius: 4px;
      height: 250px;
      position: relative;
      justify-content: unset;
    }

    @include mq('desktop') {
      padding-left: $padding-default-desktop;
    }

    &--has-status {
      background-color: rgb(0 0 0 / 70%);
      background-blend-mode: overlay;
    }

    &:not(&--has-status) {
      background-color: rgb(0 0 0 / 20%);
      background-blend-mode: overlay;
    }
  }

  &__status {
    &-icon {
      height: 42px;
      width: 42px;
      border-radius: 100%;
      margin: $padding-default;
      display: none;

      @include colorize-icon($light-grey-color-200);

      border: 1px solid $light-grey-color-200;

      @include mq('desktop-s') {
        height: 70px;
        width: 70px;
        margin: $padding-default-desktop;
        display: unset;
      }
    }

    &-title {
      color: $light-grey-color-200;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      margin: 0;
      margin-bottom: 4px;

      @include mq('tablet') {
        font-size: 25px;
        margin-bottom: 16px;
      }

      @include mq('desktop-s') {
        text-align: left;
      }
    }

    &-message {
      color: $light-grey-color-200;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      margin: 0;

      @include mq('tablet') {
        font-size: 20px;
      }

      @include mq('desktop-s') {
        text-align: left;
      }

      &--has-action {
        cursor: pointer;
        background-color: unset;
        border: unset;
      }
    }
  }
}
