@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.voucher-badge {
  padding: 8px;
  color: $promotion-tag-color;
  background-color: $promotion-tag-color-light;
  border-radius: 0 0 $border-radius-default $border-radius-default;
  font-size: rem(12);
  line-height: rem(15);
  display: inline-flex;
  align-items: center;
  width: 100%;

  .icon-marmita {
    margin-right: 6px;
    height: 12px;
    width: 12px;

    @include colorize-icon($promotion-tag-color);
  }

  &__description {
    height: 12px;
  }

  @include mq('desktop-s') {
    .icon-marmita {
      margin-right: 6px;
      height: 14px;
      width: 14px;
    }
  }
}
