@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-scheduling {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 16px;

  @include mq('tablet', max) {
    padding: 16px 16px 0 0;
  }

  @media (max-width: 370px) {
    justify-content: center;
  }

  @include mq-between('mobile', 'desktop-s') {
    grid-template-columns: 40% 1fr;
  }

  @media (min-width: 768px) {
    margin-left: auto;
  }

  @include mq('desktop-s') {
    grid-template-columns: 140px 1fr;
  }

  button {
    box-shadow: none;
  }
}
