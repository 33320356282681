@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.payment-type-selection {
  @include mq('desktop-s', 'max') {
    padding-bottom: 10px;
  }

  .selection-type {
    display: flex;
    align-items: center;
    height: 40px;
    overflow: hidden;
    padding: 0 $padding-default 0 0;
    position: relative;

    @include colorize-ink($medium-grey-color);

    &__label {
      display: block;
      flex-grow: 2;
      font-size: rem(15);
      color: $black-color;
    }
  }

  @include mq('desktop-s') {
    display: flex;
    padding-top: 18px;

    .selection-type {
      flex-direction: row-reverse;
      padding: 0 40px 0 0;

      .marmita-radio {
        margin-right: 10px;
      }
    }
  }
}
