@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-rating {
  color: $default-rating-color;
  font-size: rem(12);
  font-weight: bold;
  flex-shrink: 0;
  height: auto;
  min-width: 40px;
  display: flex;
  align-items: center;
  line-height: 18px;

  @include mq('desktop-s') {
    font-size: rem(14);
  }

  @include colorize-icon($mostard-color);

  &#{&}--secondary-colors {
    display: inline;
    color: white;
    background-color: $yellow-raincoat;
    border-radius: 10px;
    padding: 4px 8px;

    .icon-marmita {
      vertical-align: text-top;

      svg {
        fill: white;
      }
    }
  }

  .icon-marmita {
    height: 10px;
    width: 10px;
    position: relative;
    margin: 0 5px 0 0;
    top: -1px;

    @include mq('desktop-s') {
      height: 12px;
      width: 12px;
      margin: 0 5px 0 0;
    }
  }

  svg {
    height: 13px;
  }

  &--no-rating {
    color: $no-rating-color;

    @include mq('tablet') {
      line-height: 19px;
    }

    .icon-marmita {
      @include colorize-icon($no-rating-color);
    }
  }

  &__button {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }

  &__rating-wrapper {
    position: absolute;
    right: 0;
    top: 0;

    @include mq('desktop-s') {
      position: initial;
      right: inherit;
      top: inherit;
    }

    a {
      display: flex;
    }
  }

  &__rating-count {
    margin: 0 0 0 10px;
    color: $text-color-lighten;
    font-weight: 300;
    font-size: rem(16);
    display: none;
    line-height: rem(22);

    @include mq('tablet') {
      display: inline-block;
    }
  }
}
