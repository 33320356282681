@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.rating-evaluations {
  padding: 0;
  width: 100%;

  .rating-evaluation {
    width: 100%;

    &__wrapper {
      border-bottom: 1px solid #eaeaea;
      padding: 20px 0;

      &:last-child {
        border-bottom: 0;
      }
    }

    &__user {
      font-weight: 300;
      color: $text-color;
      margin-top: 10px;
      margin-bottom: 0;
      line-height: 1.46;
    }
  }

  &__answers {
    line-height: normal;
    font-size: rem(14);
    color: $masala-color;
  }
}
