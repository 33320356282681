@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
@keyframes cow-movement {
  0% {
    transform: rotateZ(0) translate(0, 0);
  }

  50% {
    transform: rotateZ(0.5deg) translate(2px, 3px);
  }

  100% {
    transform: rotateZ(0) translate(0, 0);
  }
}

@keyframes restaurant-movement {
  0% {
    transform: rotateZ(0) translate(0, 0);
  }

  50% {
    transform: rotateZ(1deg) translate(-1px, 2px);
  }

  100% {
    transform: rotateZ(0) translate(0, 0);
  }
}

@keyframes ship-movement {
  0% {
    transform: rotateZ(0) translate(0, 0);
  }

  50% {
    transform: rotateZ(3deg) translate(1px, -1px);
  }

  100% {
    transform: rotateZ(0) translate(0, 0);
  }
}

.restaurant-empty-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  &__title {
    font-size: 16px;
    line-height: 18px;
    margin-top: 30px;
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
    font-weight: lighter;
    color: $text-color;
    text-align: center;
  }

  .animated-svg {
    svg {
      width: 180px;

      @include mq('desktop-s') {
        width: 240px;
      }
    }
  }
}
