@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.debit-disclaimer-dialog {
  padding: $padding-default;

  &__title {
    color: $black-color;
    font-size: rem(22);
    font-weight: 500;
    margin: 0 0 10px;
    text-align: center;
  }

  &__description {
    color: $medium-grey-color;
    font-size: rem(16);
    line-height: 22px;
    margin: 0 0 10px;
    text-align: center;
  }

  &__icon {
    display: block;
    margin: 0 auto 20px;
    max-width: 48px;
  }
}
