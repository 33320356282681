@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.cart-bar {
  $cart-bar-height: 50px;

  height: $cart-bar-height;
  width: 100%;
  font-weight: 500;
  text-align: center;
  color: #fff;
  overflow: hidden;

  &--with-home-button {
    height: $cart-bar-height + $home-button-height;
  }

  @include mq('desktop-s') {
    display: none;
  }

  &__wrapper {
    transform: translateY(-$cart-bar-height);
    transition: transform 300ms $ease-out-quad;
    transition-delay: 300ms;

    &--new-item {
      transition: none;
      transform: translateY(0);
    }
  }

  &__container {
    display: flex;
    height: $cart-bar-height;
    width: 100%;
    padding: $padding-default;
    position: relative;
    justify-content: space-between;
    align-items: center;

    & > span {
      display: table;
      vertical-align: baseline;
    }
  }

  &__bag {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .icon-marmita {
      height: 21px;
      width: 21px;

      path {
        stroke: #fff;
      }
    }
  }

  &__amount {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    min-width: 16px;
    font-size: rem(8);
    background-color: #fff;
    color: $primary-color;
    border: 2px solid $primary-color;
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: -7px;
    padding: 2px 0 0;
  }

  &__title {
    position: absolute;
    width: 100%;
    left: 0;
    font-size: rem(13);
  }

  &__cost {
    font-size: rem(14);
  }
}
