@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchant-details-about {
  padding: 21px 0;

  @include mq('tablet') {
    padding: 33px 0;
  }

  &__description {
    font-weight: 400;
    font-size: rem(14);
    color: #717171;
    margin: unset;

    &-see-more-button {
      font-weight: 400;
      font-size: rem(14);
      background: unset;
      border: unset;
      padding: unset;
      color: #3e3e3e;
      margin-top: 4px;
    }
  }

  &__super {
    display: flex;
    flex-direction: column;
    padding: $padding-default;
    background: $light-grey-color-200;
    max-width: 500px;
    width: 100%;
    border-radius: $border-radius-default;
    margin: 28px 0;

    .icon-marmita {
      @include colorize-icon($super-color);

      margin-right: 20px;
    }

    &-wrapper {
      display: flex;
      align-items: center;

      svg {
        width: 30px;
      }
    }

    &-divisor {
      margin-top: 10px;
    }

    &-title {
      color: $text-color-super-darken;
      font-size: rem(15);
      font-weight: bold;
      margin: 0;
    }

    &-description {
      color: $text-color-super-darken;
      font-size: rem(13);
      line-height: 18px;
      margin: 5px 0 0;
      font-weight: 200;
    }
  }

  &__info {
    margin: 12px 0;

    &-title {
      font-weight: 400;
      font-size: rem(18);
      color: #3e3e3e;
      margin-top: unset;
      margin-bottom: 12px;
    }

    &-data {
      font-weight: 400;
      font-size: rem(14);
      color: #717171;
      margin: unset;
      line-height: 20px;
    }
  }

  &__footer-hint {
    color: $medium-grey-color;
    font-size: rem(13);
    line-height: 18px;
    margin: auto 0;
    text-align: center;
    padding: 20px;
    border-top: 1px solid #f2f2f2;
  }
}
