@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.delivery-method-button {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family;
  cursor: pointer;
  border-radius: 4px;
  position: relative;

  @media (min-width: 768px) {
    height: 78px;
    border: solid 1px $light-grey-color-300;

    &--selected {
      border-color: $primary-color;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 768px) {
      width: 92%;
    }
  }

  &__area {
    display: flex;
    width: 50%;
    height: 100%;

    &--column-alignment {
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      height: 65%;

      @media (min-width: 768px) {
        height: 70%;
      }
    }

    &--row-alignment {
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  &__title-container {
    align-items: center;
    display: flex;
  }

  &__title {
    font-size: rem(14);
    line-height: 20px;
    margin: 0;
    color: $text-color-super-darken;

    &--disabled {
      color: $text-color-lighten;
    }

    &--express {
      color: $primary-color;
    }

    @media (min-width: 768px) {
      font-size: rem(16);
    }

    svg {
      margin-right: 8px;
    }
  }

  &__help-icon {
    color: $text-color-lighten;
    border: solid 1.2px $text-color-lighten;
    border-radius: 100%;
    width: 13px;
    height: 12px;
    font-size: rem(8);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 3px;
    margin: 0 0 4px 12px;

    @media (min-width: 768px) {
      height: 13px;
    }
  }

  &__time {
    padding-top: 4px;
    font-weight: 1;
    font-size: rem(12);
    line-height: 16px;
    margin: 0;
    color: $text-color-super-darken;

    &--disabled {
      color: $text-color-lighten;
    }

    @media (min-width: 768px) {
      font-size: rem(14);
      line-height: 18px;
    }
  }

  &__price {
    margin: 0 16px 0 0;
    font-weight: 500;
    font-size: rem(14);
    line-height: 18px;
    color: $text-color-super-darken;

    &--free {
      color: $green-color;
    }

    &--disabled {
      color: $text-color-lighten;
    }

    @media (min-width: 768px) {
      font-size: rem(16);
      line-height: 22px;
    }

    @media (min-width: 768px) {
      margin: 0;
    }
  }

  &__radio {
    appearance: none;
    margin: 0;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: $light-grey-color-100;
    border-color: $light-grey-color-100;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &--selected {
      border: solid 6px $primary-color;
      background-color: #fff;
    }

    @media (min-width: 768px) {
      display: none;
    }
  }
}
