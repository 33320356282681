@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-header {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  height: $navheader-height;
  overflow: hidden;
  z-index: $header-z-index;

  &:not(.restaurant-header--transparent) {
    border: 1px solid $light-grey-color;
  }

  @include mq('desktop-s') {
    display: none;
  }

  .nav-header {
    transition: background-color $animation-duration $ease-out-quad;

    &__title {
      transform: translateY(0);
      transition: transform $animation-duration $ease-out-quad;
    }

    &__right-button {
      left: 0;

      .icon-marmita {
        transform: scale(1);
        height: 22px;
        width: 22px;
      }
    }
  }

  &--transparent {
    z-index: unset;
    pointer-events: none;

    .nav-header {
      background: transparent;

      &__title {
        transform: translateY(50px);
      }

      .btn-icon {
        pointer-events: all;

        .icon-marmita {
          @include colorize-icon(#fff);
        }
      }
    }
  }

  .marmita-hint--bottom::before {
    left: unset;
    right: 8px;
  }
}
