@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.shopping-list-access-point-tooltip {
  position: relative;

  &__content {
    position: absolute;
    right: 0;
    z-index: 1;
    width: 340px;
    padding: 16px;
    margin-top: 12px;
    border-radius: 4px;
    background-color: $gray-color-700;
    color: #fff;
    text-align: center;
  }

  &__title {
    margin: 0;
    font-weight: 700;
    font-size: rem(14);
    line-height: rem(16);
  }

  &__description {
    margin: 4px 0 0;
    font-weight: 400;
    font-size: rem(14);
    line-height: rem(16);
  }
}
