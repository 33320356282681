@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.ads-banner {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.catalog-ads {
  margin-left: $space-blue;
  margin-right: $space-blue;
}

.restaurant-menu {
  .gpt-ads-bg {
    @include mq('desktop') {
      margin-top: $space-orange;
    }
  }
}

.market-page-container__ads-top {
  padding: 0 0 $space-purple 0;
}

.gpt-ads-waiting-map {
  display: none;

  @include mq('desktop') {
    display: flex;
    position: fixed;
    width: calc(100vw - 450px);
    top: $header-height;
    padding: $space-orange;
    margin-left: 450px;
    z-index: 998;
  }
}

.gpt-ads-bg,
.gpt-ads-highlight {
  padding-top: $space-orange;
  padding-bottom: $space-orange;
  background-color: $light-grey-color-100;
}

.gpt-ads-highlight {
  margin-left: -$space-blue;
  width: 100vw;

  @include mq('tablet') {
    margin-left: -$space-purple;
  }

  @include mq('desktop-s') {
    margin-left: -80px;
  }

  @include mq('desktop') {
    margin-left: -120px;
  }

  @include mq('desktop-wide') {
    margin-left: -160px;
  }

  @include mq('desktop-ultrawide') {
    margin-left: -200px;
  }
}

.gpt-ads-sticky {
  position: sticky;
  padding-top: $space-orange;
  top: $header-height;
}

.gpt-ads-market-footer {
  margin-left: -$space-orange;
  margin-right: -$space-orange;
  margin-bottom: $space-orange;
}

.gpt-ads-waiting-sidebar {
  order: 100;
  padding: 24px;
}

.authentication-steps__ads {
  @media (max-width: 370px) {
    width: 280px;
  }
}
