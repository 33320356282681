@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.rating-stars {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &__group {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &--inactive svg {
      fill: $light-grey-color-300;
    }

    &--active {
      position: absolute;
      left: 0;

      svg {
        fill: $yellow-raincoat;
      }
    }
  }

  &__star {
    margin: 0 2px;
  }
}
