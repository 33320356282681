@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchant-details-payment {
  padding-top: 12px;

  &__payment {
    padding-bottom: 20px;
    color: $masala-color;

    &-type {
      padding: 20px 0 10px;

      &-title {
        font-size: rem(16);
        font-weight: 500;
        margin: 0;
      }

      &-card {
        align-items: center;
        background: $light-grey-color;
        border-radius: $border-radius-default;
        color: $black-color;
        display: inline-flex;
        font-size: rem(12);
        font-weight: 300;
        justify-content: space-between;
        margin: 0 10px 10px 0;
        padding: 10px;

        .icon-marmita {
          height: 16px;
          margin-right: 5px;
          width: 20px;
        }
      }
    }

    &-subtype {
      font-size: rem(16);
      margin: 20px 0 10px;
    }
  }

  &__footer-hint {
    color: $medium-grey-color;
    font-size: rem(13);
    line-height: 18px;
    margin: auto 0;
    text-align: center;
    padding: 20px;
    border-top: 1px solid #f2f2f2;
  }
}

.payment-tag {
  display: inline-block;
  background-color: $almost-white-color;
  padding: 8px;
  margin: 4px 4px 4px 0;
  border-radius: 4px;

  img {
    display: inline-block;
    height: 20px;
    margin-right: 8px;
    position: relative;
  }

  img,
  small {
    font-size: rem(16);
    line-height: 1em;
    vertical-align: middle;
  }
}
