@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.shopping-list-access-point-button {
  font-weight: 400;
  height: 28px;
  padding: 0 8px;
  margin: 0;
  color: $primary-color;

  .btn__icon.icon-marmita--shopping-list {
    width: 18px;
    height: 24px;
    margin-right: 16px;
  }
}
