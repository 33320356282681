@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.store-closed-alert {
  display: flex;
  flex-direction: column;
  background: white;
  max-width: 450px;
  border-radius: $border-radius-default;
  padding: 0 50px;
  margin: 0 auto;

  &__title {
    font-size: rem(24);
    color: $input-color;
    font-weight: 500;
  }

  &__description {
    font-size: rem(16);
    color: $text-color;
  }

  &__button {
    margin-top: 40px;
    margin-bottom: 18px;
  }
}
