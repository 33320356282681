@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.day-small-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  height: 69px;
  width: 69px;
  padding: 12px;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 0 4px rgb(0 0 0 / 5%);
  border: 1px solid $light-grey-color-100;

  @include mq('desktop-s') {
    cursor: pointer;
  }

  &__title {
    color: $text-color-lighten;
    font-size: rem(14);
    line-height: 26px;
    width: 100%;
  }

  &__number {
    color: $text-color-super-darken;
    font-size: rem(20);
    font-weight: 500;
    width: 100%;
  }

  &--grey {
    .day-small-card__title,
    .day-small-card__number {
      color: $light-grey-color-300;
    }
  }

  &--emphasys {
    .day-small-card__title {
      color: $primary-color;
    }
  }
}
