@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.merchant-details {
  &__button {
    display: flex;
    align-items: center;
    outline: none;
    border: 0;
    background-color: transparent;
    color: $primary-color;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    cursor: pointer;

    @include mq('tablet') {
      font-size: 16px;
      line-height: 19.79px;
      font-weight: 700;
    }
  }

  &__button-close {
    margin-top: 40px;
    border: 1px solid $primary-color;

    @include mq('desktop-s') {
      display: none;
    }
  }
}
