@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.fast-menu-mobile {
  position: relative;

  .marmita-scrollbox {
    height: 46px;
  }

  @include mq('tablet') {
    .marmita-scrollbox__container {
      padding: 0 $padding-default;
    }
  }

  .btn {
    display: inline-block;
    width: max-content;
    color: $medium-grey-color;
    height: 44px;
    margin: 0;
    padding: 0 10px;
    border-radius: 0;
  }

  &--selected {
    border-bottom: 2px solid $primary-color;

    .btn {
      color: $primary-color;
    }
  }
}
