@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-delivery-button {
  background: #fff;
  border: 1px solid $light-grey-color-100;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  border-radius: $border-radius-default;
  font-size: 14px;
  line-height: 18px;
  color: $text-color-darken;

  @include mq('mobile') {
    min-width: 139px;
    flex: 1;
    padding: 0;
  }

  @include mq('tablet') {
    height: 62px;
  }

  @media (min-width: 768px) {
    cursor: pointer;
  }

  &__grid {
    // Safari does not support grid in buttons, isolating grid layout into __grid
    // https://github.com/rachelandrew/gridbugs#10-some-html-elements-cant-be-grid-containers
    // https://codepen.io/rachelandrew/pen/dzXwRJ
    display: grid;
    align-items: center;
    grid-template: 1fr/ 1fr 20px;

    @include mq('mobile') {
      grid-template: 1fr / 20px 1fr 20px;
      padding: 0 16px;
    }
  }

  &__icon {
    display: none;

    @include mq('mobile') {
      display: block;
    }
  }

  &__arrow-icon {
    display: block;
    max-width: 24px;
    fill: $primary-color;
    color: $primary-color;
  }
}
